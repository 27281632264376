import styled from "styled-components"
const HistoryStyled = styled.div`
  .container {
    max-width: 890px;
    @media (max-width: 940px) {
      max-width: 600px;
    }
    @media (max-width: 680px) {
      max-width: calc(100% - 40px);
    }
    @media (max-width: 450px) {
      max-width: calc(100% - 20px);
    }
  }
  .history {
    &__text_in_img {
      padding-bottom: 70px;
      height: 530px;
      /* @media (max-width: 1400px) { */
      /* } */
      @media (max-width: 940px) {
        height: 460px;
      }
      @media (max-width: 540px) {
        height: 400px;
      }
      & > div {
        padding: 15% 0 0;
        margin-bottom: 15%;
        @media (max-width: 540px) {
          padding: 0;
          margin-bottom: 0;
        }
      }
      & > :before,
      & > :after {
        height: 50%;
      }
    }
    &__description {
      padding-bottom: 50px;
      h2 {
        font-size: 36px;
        line-height: 1.5;
        font-weight: 400;
        @media (max-width: 1180px) {
          font-size: 28px;
        }
        @media (max-width: 540px) {
          font-size: 25px;
        }
        @media (max-width: 450px) {
          font-size: 22px;
        }
      }
      p {
        color: #7c7c7c;
        line-height: 1.5;
      }
    }
    &__history {
      padding-bottom: 100px;
    }
    &__team {
      .teamSingle {
        width: calc(50% - 25px);
        @media (max-width: 640px) {
          width: 100%;
          margin: 0 auto;
        }
        &:last-of-type {
          margin-right: 0;
          @media (max-width: 640px) {
            width: 100%;
            margin: 0 auto;
          }
        }
      }
    }
  }
`
export default HistoryStyled
