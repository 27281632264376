import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import Builder from "../components/builder"
import HistoryStyled from "../components/styles/HistoryStyles"
import TeamStyled from "../components/styles/TeamStyles"
import SingleTeam from "../components/singleTeam"

const HistoryPage = ({ data }) => {
  const { history_builder } = data.wordpressPage.acf
  const { nodes } = data.allWordpressWpTeam
  const { title, canonical } = data.wordpressPage.yoast

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        {canonical ? <link rel="canonical" href={canonical} /> : "Start - Dapr"}
        {/* {data.yoast.map(meta_value => {
          return (
            <meta
              name={meta_value.name || meta_value.property}
              content={meta_value.content}
            />
          )
        })} */}
      </Helmet>
      <Layout>
        <HistoryStyled>
          <div className="background__white">
            <section className="background__white ">
              <div className="container">
                <h2 className="heading--1">Historia DAPR</h2>
              </div>
            </section>
            {history_builder.builder.map((item, index) => {
              switch (item.acf_fc_layout) {
                case "text_in_img":
                  return (
                    <section
                      className="history__text_in_img"
                      key={`history__${index}`}
                    >
                      <Builder
                        loyaut={item.acf_fc_layout}
                        text={item.text}
                        img={item.img}
                        topBackground={item.top_background}
                        bottomBackground={item.bottom_background}
                      />
                    </section>
                  )
                  break
                case "description":
                  return (
                    <section
                      className="history__description"
                      key={`history__${index}`}
                    >
                      <Builder
                        loyaut={item.acf_fc_layout}
                        text={item.description}
                      />
                    </section>
                  )
                  break
                case "history":
                  return (
                    <section
                      className="history__history"
                      key={`history__${index}`}
                    >
                      <Builder loyaut={item.acf_fc_layout} data={item.row} />
                    </section>
                  )
                  break

                default:
                  break
              }
            })}
          </div>
          <TeamStyled>
            <section className="background__white history__team ">
              <div className="container">
                <div className="team__conteiner">
                  {nodes.map((item, index) => (
                    <SingleTeam
                      key={`team__${index}`}
                      name={item.acf.name}
                      company_position={item.acf.company_position}
                      short_info={item.acf.short_info}
                      img={item.acf.img}
                      slug={item.slug}
                      team_mail_text={item.acf.team_mail_text}
                      team_mail_url={item.acf.team_mail_url}
                      team_phone_text={item.acf.team_phone_text}
                      team_phone_url={item.acf.team_phone_url}
                      team_linkedIn_url={item.acf.team_linkedIn_url}
                      team_linkedin_text={item.acf.team_linkedin_text}
                    />
                  ))}
                </div>
              </div>
            </section>
          </TeamStyled>
        </HistoryStyled>
      </Layout>
    </>
  )
}

export default HistoryPage

export const query = graphql`
  query getHistoryPage {
    wordpressPage(title: { eq: "Historia DAPR" }) {
      id
      yoast {
        title
        opengraph_description
        opengraph_image
        opengraph_title
        metakeywords
        metadesc
        canonical
        focuskw
        linkdex
        meta_robots_adv
        meta_robots_nofollow
        meta_robots_noindex
        redirect
        twitter_image
        twitter_title
        twitter_description
      }
      acf {
        history_builder {
          builder {
            acf_fc_layout
            text
            top_background
            bottom_background
            img {
              source_url
              title
              localFile {
                childImageSharp {
                  fixed(width: 890, quality: 90) {
                    srcSetWebp
                    srcWebp
                    srcSet
                    src
                    width
                    height
                  }
                }
              }
            }
            description
            row {
              date_year
              date_month
              description
            }
          }
        }
      }
    }
    allWordpressWpTeam(
      filter: { title: { in: ["Przemysław Przydatek", "Mikołaj Otmianowski"] } }
    ) {
      nodes {
        id
        slug
        acf {
          name
          company_position
          short_info
          team_mail_text
          team_mail_url
          team_phone_text
          team_phone_url
          team_linkedIn_url
          team_linkedin_text
          img {
            localFile {
              childImageSharp {
                fixed(width: 410, quality: 90) {
                  src
                  srcSet
                  srcSetWebp
                  srcWebp
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  }
`
