import React, { useState } from "react"
import Button from "./button"
import Img from "gatsby-image"
import VisibilitySensor from "react-visibility-sensor"
import placeholderImg from "../../static/teamp_tmp.svg"
const SingleTeam = ({
  name,
  company_position,
  short_info,
  img,
  slug,
  team_mail_text,
  team_mail_url,
  team_phone_text,
  team_phone_url,
  cooperation,
  team_linkedIn_url,
  team_linkedin_text,
  logo,
  link,
}) => {
  // console.log(link)
  const url = "/zespol/" + slug
  const [isVisible, setVisibility] = useState(false)

  const onChange = visiblity => {
    if (!!visiblity) {
      setVisibility(visiblity)
    }
  }
  return (
    <VisibilitySensor scrollCheck={true} partialVisibility onChange={onChange}>
      <div className={`teamSingle ${isVisible ? "show" : ""}`}>
        {cooperation === "partners" ? (
          <a href={link} className="teamSingle__img partners__img">
            {logo ? (
              <Img fixed={logo.localFile.childImageSharp.fixed} />
            ) : (
              <img src={placeholderImg} />
            )}
          </a>
        ) : (
          <>
            <span className="teamSingle__img">
              {img ? (
                <Img fixed={img.localFile.childImageSharp.fixed} />
              ) : (
                <img
                  className="teamSingle__img__placeholder"
                  src={placeholderImg}
                />
              )}
            </span>
            <div className="teamSingle__content">
              <p className="teamSingle__name">{name}</p>
              <p className="teamSingle__position">{company_position}</p>
              {team_phone_text || team_mail_text || team_linkedin_text ? (
                <div className="teamSingle__contact">
                  {team_phone_text ? (
                    <a
                      className="teamSingle__contact__phone"
                      href={team_phone_url}
                    >
                      {team_phone_text}
                    </a>
                  ) : (
                    ""
                  )}
                  {team_mail_text ? (
                    <a
                      className="teamSingle__contact__email"
                      href={team_mail_url}
                    >
                      {team_mail_text}
                    </a>
                  ) : (
                    ""
                  )}
                  {team_linkedin_text ? (
                    <a
                      className="teamSingle__contact__linkedin"
                      href={team_linkedIn_url}
                      target="_blank"
                    >
                      {team_linkedin_text}
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
              {/* {cooperation === "main" ? (
            <div className="teamSingle__button">
              <Button
                text="WIĘCEJ"
                link={url}
                color="transparent"
                hover_color="blue"
                position="left"
                modal={true}
              />
            </div>
          ) : (
            ""
          )} */}
            </div>
          </>
        )}
      </div>
    </VisibilitySensor>
  )
}

export default SingleTeam
