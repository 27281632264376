import styled from "styled-components"
import Phone from "../../images/phone.svg"
import Email from "../../images/email.svg"
import Linkedin from "../../images/linkedin.svg"

const TeamStyled = styled.div`
  .team {
    &__content {
      padding-bottom: 30px;
      h2 {
        font-size: 46px;
        padding-bottom: 30px;
        /* color: #191c1d; */
        font-weight: 400;
        @media (max-width: 1180px) {
          font-size: 40px;
          line-height: 38px;
          padding-bottom: 30px;
          br {
            display: none;
          }
        }
        @media (max-width: 540px) {
          font-size: 35px;
          line-height: 30px;
        }
        @media (max-width: 450px) {
          font-size: 30px;
          line-height: 28px;
        }
      }
      p {
        font-size: 24px;
        color: #7c7c7c;
        @media (max-width: 1180px) {
          br {
            display: none;
          }
        }
        @media (max-width: 540px) {
          font-size: 20px;
        }
      }
    }
    &__conteiner {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .teamSingle {
    max-width: 410px;
    width: calc(33.33% - 33px);
    margin-right: 50px;
    padding-bottom: 100px;
    transition: 0.3s all linear;
    transform-origin: top center;
    transform: translateY(-50px) scale(0.5);
    opacity: 0.4;
    @media (max-width: 1400px) {
      max-width: 320px;
      padding-bottom: 30px;
    }
    @media (max-width: 1180px) {
      margin-right: 40px;
    }
    @media (max-width: 940px) {
      width: calc(50% - 20px);
      max-width: 260px;
      margin-right: 80px;
    }
    @media (max-width: 680px) {
      width: calc(50% - 20px);
      max-width: 300px;
      margin-right: 39px;
    }
    @media (max-width: 580px) {
      width: 100%;
      margin-right: initial;
      margin: 0 auto;
    }
    &.show {
      transform: translateY(0) scale(1);
      opacity: 1;
    }
    .gatsby-image-wrapper {
      @media (max-width: 1400px) {
        max-width: 100%;
        height: 440px !important;
      }
      @media (max-width: 1180px) {
        height: 360px !important;
      }
    }
    &:nth-of-type(3n) {
      margin-right: 0;
    }
    @media (max-width: 940px) {
      &:nth-of-type(3n) {
        margin-right: 80px;
      }
      &:nth-of-type(2n) {
        margin-right: 0;
      }
    }
    @media (max-width: 680px) {
      &:nth-of-type(3n) {
        margin-right: 39px;
      }
      &:nth-of-type(2n) {
        margin-right: 0;
      }
    }
    @media (max-width: 580px) {
      &:nth-of-type(2n) {
        width: 100%;
        margin-right: initial;
        margin: 0 auto;
      }
      &:nth-of-type(3n) {
        width: 100%;
        margin-right: initial;
        margin: 0 auto;
      }
    }
    &__img {
      padding-bottom: 30px;
      /* display: block; */
      min-height: 590px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 940px) {
        min-height: 390px;
      }
      img {
        @media (max-width: 1400px) {
          max-width: 100% !important;
        }
      }
      &__placeholder {
        width: 100%;
        height: auto;
        @media (max-width: 680px) {
          width: auto;
          display: block;
          margin: 0 auto;
          height: 360px !important;
        }
      }
    }
    .partners__img {
      min-height: 140px;
      img {
        object-fit: contain !important;
      }
      .gatsby-image-wrapper {
        @media (max-width: 1400px) {
          max-width: 100%;
          height: 130px !important;
        }
        @media (max-width: 1180px) {
          height: 110px !important;
        }
      }
    }
    &__content {
    }
    &__name {
      font-size: 24px;
      font-weight: 600;
      @media (max-width: 940px) {
        font-size: 20px;
      }
    }
    &__position {
      font-size: 24px;
      color: #05b7ba;
      padding-bottom: 30px;
      min-height: 4em;
      @media (max-width: 940px) {
        font-size: 20px;
      }
      @media (max-width: 680px) {
        font-size: 20px;
      }
      @media (max-width: 580px) {
        min-height: initial;
      }
    }
    &__info {
      padding: 20px 0 10px;
      color: #7c7c7c;
      min-height: 8em;
    }
    &__contact {
      padding-bottom: 25px;
      &__phone {
        display: block;
        text-decoration: none;
        color: #05b7ba;
        margin: 5px 0;
        &:before {
          content: "";
          display: inline-block;
          height: 15px;
          width: 20px;
          margin-right: 10px;
          background: url(${Phone});
          background-position: center;
          background-repeat: no-repeat;
        }
      }
      &__email {
        display: block;
        text-decoration: none;
        color: #05b7ba;
        margin: 5px 0;
        &:before {
          content: "";
          display: inline-block;
          height: 15px;
          width: 20px;
          margin-right: 10px;
          background: url(${Email});
          background-position: center;
          background-repeat: no-repeat;
        }
      }
      &__linkedin {
        display: block;
        text-decoration: none;
        color: #05b7ba;
        margin: 5px 0;
        &:before {
          content: "";
          display: inline-block;
          height: 15px;
          width: 20px;
          margin-right: 10px;
          background: url(${Linkedin});
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }
    &__button {
    }
  }
`
export default TeamStyled
